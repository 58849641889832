import React, { useEffect } from "react";
import Cards from "./Cards";
import { GrLinkedin } from "react-icons/gr";
import Slider from "react-slick";
import NArrow from "./NextArrow";
import PArrow from "./PrevArrow";
import { useState } from "react";
const data = [{
        image: require("../Assets/Images/team-imgs/waseemboss.jpg"),
        title: "Mr. Waseem Samad",
        desc: "Group CEO",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "https://www.linkedin.com/in/waseem-samad-2a56a889"
    },
    {
        image: require("../Assets/Images/team-imgs/ikram.jpg"),
        title: "Mr. Ikram Muhammad",
        desc: "Group COO",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "https://www.linkedin.com/in/muhammad-ikram-16523931"
    },
    {
        image: require("../Assets/Images/team-imgs/zaib.jpg"),
        title: "Mr. Zaib Alam",
        desc: "Senior Economic Lead",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "https://www.linkedin.com/in/zaib-alam-80abab60"
    },
    // {
    //     image: require("../Assets/Images/team-imgs/izhar.jpg"),
    //     title: "Mr. Izhar Abbas",
    //     desc: "Chief Investment officer",
    //     // icon:<GrLinkedin />,
    //     icon: require("../Assets/Images/team-imgs/linkedin.png"),
    //     href: "https://www.linkedin.com/in/izhar-abbas-fmva%C2%AE-8ba98548"
    // },
    {
        image: require("../Assets/Images/team-imgs/surush.jpg"),
        title: "Mr. Syed Surush Kamil",
        desc: "lead Research, Development & Partnerships",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "https://www.linkedin.com/in/sskamil"
    },
    {
        image: require("../Assets/Images/team-imgs/Jamal.jpeg"),
        title: "Jamal Haider",
        desc: "Cost & Financial Controller",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "#"
    },
    {
        image: require("../Assets/Images/team-imgs/kaleem.jpg"),
        title: "Mr. Kaleem Ullah Khan",
        desc: "Chief Finance Officer",
        // icon:<GrLinkedin />,
        icon: require("../Assets/Images/team-imgs/linkedin.png"),
        href: "#"
    },


];
const MySlider = () => {
        const [progress, setProgress] = useState(0);
        const [slidesToShow, setSlideToShow] = useState(2);

        const setSlides = () => {
            if (window.innerWidth <= 1280 && window.innerWidth > 1000) {
                setSlideToShow(3);
            } else if (window.innerWidth <= 1000 && window.innerWidth > 650) {
                setSlideToShow(2);
            } else if (window.innerWidth <= 650) {
                setSlideToShow(1)
            }
        }
        useEffect(() => {
            setSlides()
            setProgress(100 / (data.length - slidesToShow + 1))
            window.addEventListener("resize", () => { setSlides() })
        }, []);
        const settings = {
            arrows: true,
            Infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 2,
            mobileFirst: true,
            nextArrow: < NArrow / > ,
            prevArrow: < PArrow / > ,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    mobileFirst: true,
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    mobileFirst: true,
                }
            }, {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    mobileFirst: true
                }
            }],
            afterChange: (current) => {
                setProgress((100 / (data.length - slidesToShow + 1)) * (current + 1));
                console.log(slidesToShow);
            },
        };
        return ( <
            div className = "relative  main bg-[#fff]" >
            <
            Slider {...settings } > {
                data.map((el, index) => < Cards imge = { el.image }
                    title = { el.title }
                    desc = { el.desc }
                    icon = { el.icon }
                    href = { el.href }
                    />)
                } <
                /Slider> <
                div className = "h-[2px] bg-gray-600 w-[83vw] absolute lg:-bottom-10 -bottom-3 right-10"
                id = "custom_teamLine" >
                <
                div
                className = "bg-[#F89252] absolute  h-[100%] transition-all"
                style = {
                    { width: `${progress}%` }
                } >
                <
                /div> < /
                div > <
                /div>
            );
        };

        export default MySlider;