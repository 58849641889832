import React from 'react'
import Image1 from "../../Assets/Images/news/hdimage1.jpeg";
import Image2 from "../../Assets/Images/news/hdimage2.jpeg";
import Image3 from "../../Assets/Images/news/hdimage3.jpeg";
import Image4 from "../../Assets/Images/news/hdimage4.jpeg";


const News = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto py-[100px] px-2">
        <div className="flex justify-center text-xl md:text-2xl font-bold mb-4">
          News Information
        </div>

        <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 p-2 xl:p-5">
          {
            news_data.map(item => (
              <li key={item.id} className="relative h-[500px] pb-4 overflow-y-scroll bg-white flex flex-col justify-start border rounded shadow-md hover:shadow-primary-400">
                <div className="relative">
                  <div className="relative w-full h-[270px] aspect-video overflow-hidden">
                    <img className="w-full h-[220px] object-cover rounded mb-4 transitio ease-in-out duration-150 delay-75 hover:scale-110"
                      src={item.img} alt="Writey A.I" loading="lazy" />
                    <div
                      className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-b from-gray-800 to-gray-400 text-white">
                      <p className="font-medium text-sm ">{item.title}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-beetween gap-3 px-4 py-2">
                  <p className="text-gray-600 two-lines text-ellipsis">
                    {item.desc}
                  </p>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </>
  )
}

export default News;

const news_data = [
  {
    id: 1,
    img: Image1,
    title: "Groundbreaking Ceremony for Rupani Regional Centre of Excellence, Islamabadn",
    desc: " The groundbreaking ceremony for the Rupani Regional Centre of Excellence was inaugurated by Mr. Ahsan Iqbal Chaudhary, Minister for Planning, Development, and Reforms, marking a pivotal step by Rupani Development Initiatives (RDI) in advancing Early Childhood Development (ECD) education in Pakistan. This new state-of-the-art facility will serve as a research hub, a model ECD center, and a specialized library dedicated to ECD.  The event was graced by the presence of Mr. Nasruddin Rupani, Chairman and Founder of Rupani Development Initiatives, along with Ms. Farida Rupani; Prof. Dr. Nasir Mahmood, Vice Chancellor of Allama Iqbal Open University; and Mr. Syed Jalaluddin S., Global CEO of Rupani Development Initiatives. The ceremony also witnessed the participation of the leadership of RDI and esteemed faculty members from Allama Iqbal Open University, making it a significant milestone in the journey to revolutionize ECD education. The Rupani Regional Centre of Excellence is poised to play a crucial role in shaping the future of ECD education by fostering research, innovation, and capacity building. It represents a collaborative effort to promote quality education and empower communities, contributing to sustainable development across the region.   Stay tuned for more updates as this visionary project progresses towards transforming early childhood education in Pakistan."
  },
  {
    id: 2,
    img: Image2,
    title: "Groundbreaking of Al-Shifa Trust Eye Hospital in Gilgit-Baltistan",
    desc: "We are thrilled to announce the groundbreaking of the Al-Shifa Trust Eye Hospital in Gilgit-Baltistan, a project proudly supported by the Rupani Foundation. This state-of-the-art facility will be the first in the region to provide advanced eye care, pediatric services, and mental well-being support. Our collaboration with Al-Shifa Eye Trust marks a significant step towards improving healthcare access for the people of Gilgit-Baltistan. Since 2007, the Rupani Foundation has been dedicated to enhancing the quality of life for individuals and communities across Pakistan. The establishment of this hospital is a testament to our commitment to empowering our nation's people economically and socially. We believe that together, we can ensure every citizen has access to essential services and opportunities for growth. "
  },
  {
    id: 2,
    img: Image3,
    title: "Chief Minister Haji Gulbar Khan, Commander FCNA Major General Kashif Khalil Sahib",
    desc: "We extend our heartfelt gratitude to Chief Minister Haji Gulbar Khan, Commander FCNA Major General Kashif Khalil Sahib, and all our esteemed partners for their invaluable support. The event was also graced by the presence of Mr. Nasruddin Rupani, Founder and Chairman of Rupani Development Initiatives, and Mrs. Farida Rupani, highlighting the significance of this milestone. Let us continue to work together to build a brighter future for Gilgit-Baltistan and our beloved Pakistan. Stay tuned for further updates on this transformative healthcare initiative and how it will positively impact the lives of the people in the region."
  },
  {
    id: 4,
    img: Image4,
    title: "Productive Meeting between Rupani Development Initiatives and Federal Secretary for Education",
    desc: "A highly productive meeting was held in Islamabad between Mr. Nasruddin Rupani, CEO of Rupani Development Initiatives (RDI), and Mr. Mohyuddin Ahmad Wani, Federal Secretary for Education and Professional Training. The focus of the discussion was on enhancing collaboration in key areas such as education, technical skills development, early childhood education, and community empowerment. Key figures in attendance included Syed Jalal Uddin Shah, Global CEO of RDI, Mr. Waseem Samad, Group CEO of Apex & Co, and Mr. Jalaluddin, CEO of Rupani Foundation. Their presence underscored the significance of this meeting in furthering strategic partnerships. The leaders expressed their commitment to strengthening educational initiatives and empowering communities across Pakistan. This collaborative effort aims to foster sustainable development, with a strong emphasis on capacity building and skill development, to benefit all segments of society. Stay tuned for more updates on how this collaboration will unfold and contribute to the socio-economic uplift of communities across Pakistan.",
  }
]