import React from "react";
import Header from "./Layouts/Header/Header.jsx";
import CEOMessage from "./Routers/Chairman_message/CEO_Message";
import Team from "./Routers/Team/Team";
import Footer from "./Layouts/Footer/Footer.jsx";
import {
  Route,
  Routes,
} from "react-router-dom";
import InvesterGuide from "./Routers/invester_Guide/InvesterGuide.jsx";
import Approach from "./Routers/Approaches/Approach.jsx";
import Stratigic from "./Routers/StratigicOutlook/Stratigic.jsx";
import Portfolio from "./Routers/Portfolio/Portfolio.jsx";
import Home from "./Layouts/Home/Home.jsx";
import ContactUs from "./Routers/Contact_Us/Contact_Us.jsx";
import ImpactInvesting from "./Routers/Impact_Investing1/Impact_investing.jsx";
import PopupComponent from "./Pages/Popup/index.jsx";
import News from "./Pages/News/News.jsx";
import Events from "./Pages/Events/Events.jsx";
import Media from "./Pages/Media/Media.jsx";
import Wrapper from "./Wrapper.js";
function App() {
  return (
    <React.StrictMode>
      <Wrapper>
        <Header />
        {/* <SideNav /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/approach" element={<Approach />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/Stratigic" element={<Stratigic />} />
          <Route path="/message" element={<CEOMessage />} />
          <Route path="/ourteam" element={<Team />} />
          <Route path="/investerGuide" element={<InvesterGuide />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/Impact_investing1" element={<ImpactInvesting />} />
          <Route path="/CareerJobs" element={<PopupComponent />} />
          <Route path="/News" element={<News />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Media" element={<Media />} />
        </Routes>
        <Footer />
      </Wrapper>
    </React.StrictMode>
  );
}

export default App;
