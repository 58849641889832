import React from 'react'
import location from '../Assets/Images/location.png';
import { MapContainer, Marker, TileLayer, Circle, Popup, Tooltip } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
const MapComponent = () => {
    const customIcon = new L.Icon({
        iconUrl: location,
        iconSize: [32, 32], // Size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon
        popupAnchor: [0, -32] // Popup anchor point
    });

    return (

        <>
            <MapContainer center={[35.8998, 74.3523]} zoom={10} style={{ width: "100%", height: "100%" }} >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='all reserved apexnco' />
                <Circle center={[35.8998, 74.3523]} radius={500} color="blue" fillColor="#3388ff" fillOpacity={0.5} />
                <Marker position={[35.8998, 74.3523]} icon={customIcon} >
                    <Popup>Brig. Sher Ullah Baig Rd, <br/> Jutial, Gilgit,
                        Gilgit Baltistan</Popup>
                </Marker>
            </MapContainer>

        </>
    )
}

export default MapComponent