import React from 'react'

const Events = () => {
    return (
        <div>
            <div className="h-screen mx-auto px-4 py-[100px] flex justify-center items-center">
             <h2 className='font-semibold text-3xl'>Comming Soon!</h2>
            </div>
        </div>
    )
}

export default Events